<template>
  <qrcode-vue :value="value" :size="size" level="L" />
</template>
<script>
import QrcodeVue from 'qrcode.vue'
import { getLangCodeByLang } from "~/utils/locale";
export default {
    components:{
        QrcodeVue
    },
  data() {
    return {
      link: 'https://support.weex.today/register'
    };
  },
  props: {
    size: {
      type: Number,
      default: 150
    },
    lang: {
      type: String,
      default: 'zh-CN'
    },
    shareUrl: {
      type: String,
      default: ''
    }
  },
  computed: {
    value() {
      if (this.shareUrl !== '') {
        return this.shareUrl;
      } else {
        let link = this.link;
        let languageType = getLangCodeByLang(this.lang);
        if(this.downloadJumpDl){
          link = this.downloadJumpDl('download');
        }

        return `${link}?languageType=${languageType}`;
      }
    }
  }
};
</script>
